import React from 'react';


function Footer() {

    return (
        <section className="Footer is-fixed-bottom has-background-black-ter">
            <div className="columns is-marginless">
                <div className="column is-center center">

                    <div className="nav-item center">
                        <p className="footer-text footer-spacing">J0sh.codes</p>
                        <a href="mailto:mail@j0sh.codes" rel="noopener noreferrer" target="_blank">
                            <span className="icon footer-spacing">
                              <a className="fa fa-lg fa-envelope"></a>
                            </span>
                        </a>
                        <a href="https://github.com/J05hr" rel="noopener noreferrer" target="_blank">
                            <span className="icon footer-spacing">
                              <a className="fa fa-lg fa-github"></a>
                            </span>
                        </a>
                        <a href="https://www.linkedin.com/in/jr0zenberg/" rel="noopener noreferrer" target="_blank">
                            <span className="icon footer-spacing">
                              <a className="fa fa-lg fa-linkedin"></a>
                            </span>
                        </a>
                        <p className="tiny-text footer-spacing">©2020</p>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Footer;