import React from 'react';
import NavBar from "./NavBar";
import JRAvatar from "../images/JRavatarSquare.jpg";


function Contact() {

    return (
        <div className="Contact hero is-fullh has-background-black-ter">
            <NavBar/>
            <div className="contact-container center"
                 style={
                     { backgroundImage: `linear-gradient(rgba(0,0,0,0.99), rgba(20,20,20,0.99)), url(${require("../images/background-1789175_1280.png")})`}}>
                <div className="columns">
                    <div className="column is-one-fifth center desktop">
                        <p className="code-text">&lt; </p>
                    </div>
                    <div className="column center contact-column1">
                            <div className="image-container center-column">
                                <div className="image">
                                    <img className="avatar-border avatar-image" src={JRAvatar} alt="JRAvatar"/>
                                </div>
                                <p className="name-text">Joshua Rozenberg</p>
                            </div>
                    </div>
                    <div className="column center contact-column2">
                        <div className="contacttext-container center-column">
                            <div className="res-padding-1 contact-touch-hint">
                                <a href="https://www.linkedin.com/in/jr0zenberg/" rel="noopener noreferrer" target="_blank" className="title contact-text">Linkedin</a>
                            </div>
                            <div className="res-padding-1 contact-touch-hint">
                                <a href="https://github.com/J05hr" rel="noopener noreferrer" target="_blank" className="title contact-text ">Github</a>
                            </div>
                            <div className="res-padding-1 contact-touch-hint">
                                <a href="mailto:mail@j0sh.codes" rel="noopener noreferrer" target="_blank" className="title contact-text">mail<br/>@j0sh.codes</a>
                            </div>
                        </div>
                    </div>
                    <div className="column center desktop is-gapless">
                        <p className="code-text">/&gt;</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;