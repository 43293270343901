import React from 'react';


function QContactModal(props) {
    return (
        <div className="modal" id="qcontactmodal">
            <div className="modal-background"></div>
            <div className="modal-content qmodal-content center">
                <div className="qcontact-container">
                    <div className="padding-1">
                        <a href="tel:+19738683160" rel="noopener noreferrer" target="_blank" className="title contact-text">Call or Text:  973-868-3160</a>
                    </div>
                    <div className="padding-1">
                        <a href="mailto:mail@j0sh.codes" rel="noopener noreferrer" target="_blank" className="title contact-text">Email Me at: mail@j0sh.codes</a>
                    </div>
                </div>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={()=>props.toggleModal("qcontactmodal")}></button>
        </div>
    );
}

export default QContactModal;