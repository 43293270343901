import React, {useEffect, useState} from 'react';
import {Animated} from "react-animated-css";
import NavBar from "./NavBar";
import Footer from "./Footer";
import QContactModal from "./QContactModal";

function Home() {

    let homevisits = JSON.parse(localStorage.getItem('homevisits'));
    if (homevisits == null){homevisits = 0;}

    const [visible, setVisible] = useState(true);
    const [display, setDisplay] = useState(true);
    const [skipVisible, setSkipVisible] = useState(true);

    useEffect(() => {
        if (homevisits > 0){
            setVisible(false);
            setDisplay(false);
            setSkipVisible(false);
        } else{
            setTimeout(() => setSkipVisible(false), 26000);
            setTimeout(() => setVisible(false), 4000);
            setTimeout(async() => await setDisplay(false), 9000);
        }
        localStorage.setItem('homevisits', JSON.stringify(homevisits+1));
    },[]);

    const toggleModal = (modalName) => {
        document.getElementById(modalName).classList.toggle('is-active');
    }


    return (
        <div className="Home hero is-fullh has-background-black-ter">
            <NavBar/>
            <QContactModal toggleModal = {toggleModal}/>
            {!(homevisits > 1) && <div className="column center-column" style={display ? {} : {display: 'none' }}>
                <Animated animationIn="bounceInLeft" animationInDelay="1000" animationOut="backOutDown"
                          animationInDuration="1000" animationOutDelay="4000" animationOutDuration="1000"
                          isVisible={visible}>
                    <div className="container center">
                        <h1 className="title welcome-text">Hey There!</h1>
                    </div>
                </Animated>
                <Animated animationIn="bounceInLeft" animationInDelay="3000" animationOut="backOutDown"
                          animationInDuration="1000" animationOutDelay="3000" animationOutDuration="1000"
                          isVisible={visible}>
                    <div className="container center">
                        <p className="para-text">I'm Josh. Welcome to my website.</p>
                    </div>
                </Animated>
            </div>}
            {skipVisible && <a className="intro-skip skipbalance-text">skipbalance</a>}
            {!display && <div className="container center-column abouttext-container">
                <Animated animationIn="fadeIn" animationInDelay={homevisits > 1 ? "0" : "1000"} animationInDuration={homevisits > 1 ? "0" : "4000"}>
                    <div className="container center-column padding-2">
                        <h1 className="welcome-text">Josh Rozenberg, &nbsp; Software Engineer - Farmer - Maker </h1>
                    </div>
                </Animated>
                <Animated animationIn="fadeIn" animationInDelay={homevisits > 1 ? "0" : "3000"} animationInDuration={homevisits > 1 ? "0" : "3000"}>
                    <div className="container center">
                        <p className="para-text"> I’m a software engineer with a passion for designing and building things. </p>
                    </div>
                </Animated>
                <Animated animationIn="fadeIn" animationInDelay={homevisits > 1 ? "0" : "7000"} animationInDuration={homevisits > 1 ? "0" : "3000"}>
                    <div className="container center">
                        <p className="para-text"> Professionally, I create and use data-driven digital tools to solve complex problems. </p>
                    </div>
                </Animated>
                <Animated animationIn="fadeIn" animationInDelay={homevisits > 1 ? "0" : "12000"} animationInDuration={homevisits > 1 ? "0" : "3000"}>
                    <div className="container center">
                        <p className="para-text"> I like to spend my free time farming, exploring, tinkering, and playing games. </p>
                    </div>
                </Animated>
                <Animated animationIn="fadeIn" animationInDelay={homevisits > 1 ? "0" : "14000"} animationInDuration={homevisits > 1 ? "0" : "3000"}>
                    <p className="para-text res-padding-1">
                        <br/>
                        If you want to get in touch with me you can shoot me a
                        <a className="is-link link-text" onClick={()=>toggleModal("qcontactmodal")}> text, call, or email </a> anytime.
                        <br/>
                        I'm stack agnostic and I'm always looking for new and interesting projects.
                        <br/>
                    </p>
                </Animated>
            </div>}
            {!display && <Animated animationIn="fadeIn" animationInDelay={homevisits > 1 ? "0" : "17000"} animationInDuration={homevisits > 1 ? "0" : "3000"}>
                <Footer/>
            </Animated>}
            {skipVisible && <a className="intro-skip tiny-text" onClick={()=>{window.location.reload()}}>skip</a>}
        </div>
    );
}

export default Home;