import React from 'react';
import 'bulma/css/bulma.min.css';
import 'animate.css/animate.compat.css';
import 'font-awesome/css/font-awesome.min.css';
import '../styles/app.css';
import '../styles/text.css';
import '../styles/carousel.css';
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Projects from "./Projects";
import {Route, Switch} from 'react-router-dom';



function App() {

    //return the JS
    return (
        <div className="App">
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/About" component={About}/>
                <Route exact path="/Projects" component={Projects}/>
                <Route exact path="/Contact" component={Contact}/>
                <Route exact path="*" component={Home}/>
            </Switch>
        </div>
    );
}

export default App;
