import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import JRLogo from "../images/JRLogo.png";
function NavBar() {

    let curpage = "";

    let locs = useLocation().pathname.split('/');
    let suffix = locs[1].toLowerCase();
    const home = suffix === "";
    const projects = suffix === "projects";
    const about = suffix === "about";
    const contact = suffix === "contact";

    if(home){
        curpage = "Home";
    }else if(projects){
        curpage = "Projects";
    }else if(about){
        curpage = "About";
    }else if(contact) {
        curpage = "Connect";
    }

    //make current page button none
    useEffect(() => {
        try{
            if(home) {
                document.getElementById("aboutdrop").style.display = "none";
            }else if(home) {
                    document.getElementById("navdropdown").style.display = "none";
            }else if(projects){
                document.getElementById("projectsdrop").style.display = "none";
            }else if(about){
                document.getElementById("cvdrop").style.display = "none";
            }else if(contact){
                document.getElementById("contactdrop").style.display = "none";
            }
        }catch(err){
            console.error(err.message);
        }
    },[]);

    return (
        <section className="NavBar has-background-black-ter">
            <div className="columns navbar-columns is-mobile has-text-weight-bold">
                <div className="column center left">
                    <img className="JRlogo-image" src={JRLogo} alt="JRLogo"/>
                    <Link to="/">
                        <a className="nav-item nav-text desktop">Home</a>
                    </Link>
                    <Link to="/About">
                        <a className="nav-item nav-text desktop">About</a>
                    </Link>
                    <Link to="/Projects">
                        <a className="nav-item nav-text desktop">Projects</a>
                    </Link>
                    <Link to="/Contact">
                        <a className="nav-item nav-text desktop">Connect</a>
                    </Link>
                </div>
                <div className="column navbar-column2 right is-one-fifth">
                    <p className="nav-item mobile-only cur-page-text">{curpage}</p>
                    <div id="navdropdown" className="dropdown is-right" onClick={()=>{document.querySelector(".dropdown").classList.toggle('is-active');}}>
                        <div className="dropdown-trigger">
                            <a role="button" className="nav-menu fa fa-2x fa-caret-down drop-button" aria-label="menu" aria-expanded="false"
                               aria-haspopup="true" aria-controls="dropdown-menu4">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <div className="dropdown-menu dropdown-width" id="dropdown-menu4" role="menu">
                           <div className="dropdown-content has-background-grey-darker center-column">
                                <Link to="/" onClick={()=>{document.querySelector(".dropdown").classList.toggle('is-active');}}>
                                    <p id="aboutdrop" className="nav-item box nav-box navdrop-text">Home</p>
                                </Link>
                                <Link to="/About" onClick={()=>{document.querySelector(".dropdown").classList.toggle('is-active');}}>
                                    <p id="cvdrop" className="nav-item box nav-box navdrop-text">About</p>
                                </Link>
                                <Link to="/Projects" onClick={()=>{document.querySelector(".dropdown").classList.toggle('is-active');}}>
                                    <p id="projectsdrop" className="nav-item box nav-box navdrop-text">Projects</p>
                                </Link>
                                <Link to="/Contact" onClick={()=>{document.querySelector(".dropdown").classList.toggle('is-active');}}>
                                    <p id="contactdrop" className="nav-item box nav-box navdrop-text">Connect</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NavBar;