import React from 'react';
import PYLogo from "../images/Python-logo-notext.svg";
import PGLogo from "../images/postgresql.svg";
import JVLogo from "../images/transparent-logo-png-java-icon-java-runtime-environment-png-535_535.png";
import ADLogo from "../images/android.svg";
import JSLogo from "../images/JavaScript-logo.png";
import SQLLogo from "../images/SQLite370.svg";
import HTLogo from "../images/HTML5_Logo.svg";
import RTLogo from "../images/React-icon.svg";
import NDLogo from "../images/Node.js_logo.svg";
import ARLogo from "../images/Mark_of_the_United_States_Army.svg";
import USLogo from "../images/Flag_of_the_United_States.svg";
import NGLogo from "../images/Seal_of_the_United_States_National_Guard.svg";
import QGLogo from "../images/QGIS_logo.svg";
import MVLogo from "../images/MEVO-green-848x566.png";
import SCLogo from "../images/sc-logo-green.svg";
import CS440Final from "../docs/cs440final.pdf"
import WDLogo from "../images/WORLD.svg"
import CDLogo from "../images/coding-icon1010-320x340px-300x282.png"
import NavBar from "./NavBar";
import Footer from "./Footer";
import {Link} from "react-router-dom";


function Projects() {

    const projectClicked = (rid) => {
        let id;
        for (id = 0; id < 7; id++){
            let project = "project"+id.toString();
            const pelement = document.getElementById(project);
            if (id !== rid){
                pelement.classList.remove("fadeInDownBig");
                pelement.classList.add("slow");
                pelement.classList.add("fadeOutDownBig");
                setTimeout(() => {
                        pelement.style.display = "none";
                        pelement.classList.remove("fadeOutDownBig");}
                    , 400);
            }
            else{
                setTimeout(() => {
                    pelement.style.display = "flex";
                        pelement.style.opacity = "1";
                        pelement.classList.remove("slow");
                        pelement.classList.add("fadeInDownBig");}

                    , 400);
            }
        }
    }

    return (
        <div className="Projects hero is-fullh has-background-black-ter">
            <NavBar/>
            <div className="columns projects-container">
                <div className="column pc-left right desktop">
                    <div className="plist">
                        <div className="item center" id="item0" onClick={()=>projectClicked(0)}>
                            <label className="label whatido-text label_gistools">Projects</label>
                        </div>
                        <div className="item" id="item1" onClick={()=>projectClicked(1)}>
                            <label className="label label-text label_gistools">GIS Tools</label>
                            <div className="level desktop">
                                <div className="level-item">
                                    <img className="logo-image" src={PYLogo} alt="PYLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={QGLogo} alt="QGLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={PGLogo} alt="PGLogo"/>
                                </div>
                            </div>
                        </div>
                        <div className="item" id="item2" onClick={()=>projectClicked(2)}>
                            <label className="label label-text label_resourceledger">ResourceLedger</label>
                            <div className="level desktop">
                                <div className="level-item">
                                    <img className="logo-image" src={ADLogo} alt="ADLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={SQLLogo} alt="SQLLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={JVLogo} alt="JVLogo"/>
                                </div>
                            </div>
                        </div>
                        <div className="item" id="item3" onClick={()=>projectClicked(3)}>
                            <label className="label label-text label_j0shcodes">J0sh.codes</label>
                            <div className="level desktop">
                                <div className="level-item">
                                    <img className="logo-image" src={JSLogo} alt="JSLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={RTLogo} alt="RTLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={NDLogo} alt="NDLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={HTLogo} alt="HTLogo"/>
                                </div>
                            </div>
                        </div>
                        <div className="item" id="item4" onClick={()=>projectClicked(4)}>
                            <label className="label label-text label_imagerecognition">Image Recognition</label>
                            <div className="level desktop">
                                <div className="level-item">
                                    <img className="logo-image" src={PYLogo} alt="PYLogo"/>
                                </div>
                            </div>
                        </div>
                        <div className="item" id="item5" onClick={()=>projectClicked(5)}>
                            <label className="label label-text label_nonprofit">Non-Profit</label>
                            <div className="level desktop">
                                <div className="level-item">
                                    <img className="logo-image" src={MVLogo} alt="MVLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={SCLogo} alt="SCLogo"/>
                                </div>
                            </div>
                        </div>
                        <div className="item" id="item6" onClick={()=>projectClicked(6)}>
                            <label className="label label-text label_militaryservice">Military Service</label>
                            <div className="level desktop">
                                <div className="level-item">
                                    <img className="logo-image" src={ARLogo} alt="ARLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={USLogo} alt="USLogo"/>
                                </div>
                                <div className="level-item">
                                    <img className="logo-image" src={NGLogo} alt="NGLogo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column center-column pc-right ">

                    <div className="project-tile center-column animated" id="project0">
                        <div className="pdrop-control center" onClick={()=>{
                            document.getElementById("makedrop").style.display === "flex" ?
                                document.getElementById("makedrop").style.display = "none" :
                                document.getElementById("makedrop").style.display = "flex"}}>
                            <p className="intro-project-title-text">Projects</p>

                            &nbsp;&nbsp;
                            <a role="button" className="drop-menu fa fa-2x fa-caret-down drop-button" aria-label="menu" aria-expanded="false"
                               aria-haspopup="true">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <p className="para-text">
                            I seek out and solve complex problems by creating unique solutions.
                        </p>
                        <div id="makedrop" className="item-desc center-column">
                            <div className="columns res-padding-1">
                                <div className="column center top">
                                    <div className="container center-column physW-container">
                                        <img className="plogo-image" src={CDLogo} alt="CDLogo"/>
                                        <label className="label-text ">Digital World</label>
                                        <p className="imt-text">
                                            In the digital world I create applications and systems.
                                            I develop software for all kinds of problems on the web, mobile, and PC.
                                            <br/><br/>
                                            I understand most programming languages and many modern frameworks.
                                            <br/><br/>
                                            I'm a dataphile so I'm also a little obsessed with organizing and storing data.
                                            I enjoy working with large and complex datasets.
                                            <br/><br/>
                                            The digital space is somewhere where I work and play. I build professional
                                            applications for work but I also build and use software for my hobbies.
                                        </p>
                                    </div>
                                </div>
                                <div className="column center top">
                                    <div className="container center-column DigW-container">
                                        <img className="plogo-image" src={WDLogo} alt="WDLogo"/>
                                        <label className="label-text">Physical World</label>
                                        <p className="imt-text">
                                            I have a passion for the environment and agriculture. I keep myself in good
                                            company with plants, animals, and people who care for them. I enjoy creating farms,
                                            gardens, and outdoor spaces.
                                            <br/><br/>
                                            Professionally, I'm also a geographer so I study places and people and create
                                            things like maps and policy solutions.
                                            <br/><br/>
                                            There is also a never-ending list of the things I do just for fun. That includes electronics,
                                            woodworking, metalworking, cooking, brewing beer, and many more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="level">
                                <p className="imt-text">
                                    Most of my software projects are available on github, some are private so you'll have to add
                                    <a className="link-text" rel="noopener noreferrer" target="_blank" href="https://github.com/J05hr"> me </a>first.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="project-tile center-column animated" id="project1">
                        <div className="pdrop-control center" onClick={()=>{
                            document.getElementById("gisdrop").style.display === "flex" ?
                                document.getElementById("gisdrop").style.display = "none" :
                                document.getElementById("gisdrop").style.display = "flex"}}>
                            <p className="project-title-text">GIS Tools</p>
                            &nbsp;&nbsp;
                            <a role="button" className="drop-menu fa fa-2x fa-caret-down drop-button" aria-label="menu" aria-expanded="false"
                               aria-haspopup="true">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <div id="gisdrop" className="item-desc">
                            <div className="level center-column">
                                <p className="para-text">Working at Trimble Maps
                                    on the data team, I helped to develop, maintain,
                                    and improve a plugin for QGIS that encompasses a range of complex GIS tools.
                                    <br/><br/>
                                    I was able to experience both sides of this project. Originally, I was
                                    using these tools as a member of the GIS team. After finishing my
                                    second degree I was able to come back and work on the development side.
                                    I really enjoyed this project because I was leveraging GIS skills
                                    and Software Engineering skills to produce better solutions.
                                    <br/><br/>
                                    Trimble Maps makes navigation software. Good navigation software requires high quality
                                    data. The plugin our team developed was used internally by the GIS department to edit,
                                    update, and maintain the enormous road network data. The plugin is essentially a suite
                                    of custom data tools that use QGIS as a base.
                                    <br/><br/>
                                </p>
                                <p className="para-text has-text-centered">
                                    Development stack: Python, PyQt,  PostgreSQL.
                                    <br/><br/>
                                    Still curious? Checkout more about what
                                    <a className="link-text" rel="noopener noreferrer" target="_blank" href="https://maps.trimble.com/"> Trimble Maps </a>
                                    does, or <Link to="/Contact"><a className="is-link link-text"> contact me</a></Link>.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="project-tile center-column animated" id="project2">
                        <div className="pdrop-control center" onClick={()=>{
                            document.getElementById("resdrop").style.display === "flex" ?
                                document.getElementById("resdrop").style.display = "none" :
                                document.getElementById("resdrop").style.display = "flex"}}>
                            <p className="project-title-text">ResourceLedger</p>
                            &nbsp;&nbsp;
                            <a role="button" className="drop-menu fa fa-2x fa-caret-down drop-button" aria-label="menu" aria-expanded="false"
                               aria-haspopup="true">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <div id="resdrop" className="item-desc">
                            <div className="level center-column">
                                <p className="para-text">
                                    This is a current project. It was spawned from course work in Software Engineering
                                    where our team of four developed an inventory management application for Android.
                                    This new app can still track static resources but it also aims to track dynamic
                                    resources with heredity. The primary use-case involves tracking living items such
                                    as plant and mushroom cultures.
                                    <br/><br/>
                                    This is an incredibly useful tool for both educational and commercial projects in
                                    anything that involves biology, mycology, or botany. Usually Excel or some other
                                    basic tabulation is used to track resources. That is clunky and doesn't provide
                                    visualization or heredity. Our app provides a simple and efficient solution.
                                    <br/><br/>
                                    I am leading this project with a small team of friends. It is open source
                                    and completely free to use, providing a unique and powerful tool to
                                    students and scientists.
                                    <br/><br/>
                                </p>
                                <p className="para-text has-text-centered">Development stack: Java, Android, SQLite
                                    <br/><br/>
                                    Still curious? Checkout the
                                    <a className="link-text" rel="noopener noreferrer" target="_blank" href="https://github.com/J05hr/ResourceLedger"> Github</a>
                                    , or <Link to="/Contact"><a className="is-link link-text"> contact me</a></Link>.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="project-tile center-column animated" id="project3">
                        <div className="pdrop-control center" onClick={()=>{
                            document.getElementById("codesdrop").style.display === "flex" ?
                                document.getElementById("codesdrop").style.display = "none" :
                                document.getElementById("codesdrop").style.display = "flex"}}>
                            <p className="project-title-text">J0sh.codes</p>
                            &nbsp;&nbsp;
                            <a role="button" className="drop-menu fa fa-2x fa-caret-down drop-button" aria-label="menu" aria-expanded="false"
                               aria-haspopup="true">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <div id="codesdrop" className="item-desc">
                            <div className="level center-column">
                                <p className="para-text">
                                    Yup, That's this site. Resumes and cover letters do their job, but they lack depth so I built a space
                                    for others to learn about me and see what I do.
                                    <br/><br/>
                                    Web development isn't usually my main thing but I love vertically integrating big projects
                                    and this one was a blast. I did this totally solo since it's all about me and I did it
                                    completely from scratch. It even runs on my own web server that I built and administrate.
                                    I didn't know anything about half the stack when I started.
                                    React, Node, Express, and NGINX were all new to me so I learned a ton on this one.
                                    <br/><br/>
                                </p>
                                <p className="para-text has-text-centered">
                                    Development stack: React, Nodejs, HTML, CSS, Express, NGINX, postgreSQL.
                                    <br/><br/>
                                    Still curious? Code for this project is available on
                                    <a className="link-text" rel="noopener noreferrer" target="_blank" href="https://github.com/J05hr/J0sh.codes"> Github</a>
                                    , but its private so you'll have to add
                                    <a className="link-text" rel="noopener noreferrer" target="_blank" href="https://github.com/J05hr"> me </a>
                                    first; or <Link to="/Contact"><a className="is-link link-text"> contact me</a></Link>.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="project-tile center-column animated" id="project4">
                        <div className="pdrop-control center" onClick={()=>{
                            document.getElementById("imagedrop").style.display === "flex" ?
                                document.getElementById("imagedrop").style.display = "none" :
                                document.getElementById("imagedrop").style.display = "flex"}}>
                            <p className="project-title-text">Image Recognition</p>
                            &nbsp;&nbsp;
                            <a role="button" className="drop-menu fa fa-2x fa-caret-down drop-button" aria-label="menu" aria-expanded="false"
                               aria-haspopup="true">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <div id="imagedrop" className="item-desc">
                            <div className="level center-column">
                                <p className="para-text">
                                    For a final project in an artificial intelligence course, as a team of two, we
                                    developed an image recognition application to recognize numbers and faces. We
                                    implemented a variety of classification algorithms including K-nearest neighbors,
                                    Perceptron, and Naive Bayes. We built and tested a wide range of feature extraction
                                    methods to try to optimize the classification.
                                    <br/><br/>
                                    This was an awesome project and a really fun course in general. We learned all about
                                    search algorithms like A*, Bayesian probability, and modern uses of artificial
                                    intelligence. It definitely whet my appetite for the subject and I'm looking to learn
                                    more and pickup another A.I. project in the near future.
                                    <br/><br/>
                                </p>
                                <p className="para-text has-text-centered">Development stack: Python, CLI.
                                    <br/><br/>
                                    Still curious? Checkout our
                                    <a className="link-text" rel="noopener noreferrer" target="_blank" href={CS440Final}> Report</a>
                                    ; or <Link to="/Contact"><a className="is-link link-text"> contact me</a></Link>.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="project-tile center-column animated" id="project5">
                        <div className="pdrop-control center" onClick={()=>{
                            document.getElementById("npdrop").style.display === "flex" ?
                                document.getElementById("npdrop").style.display = "none" :
                                document.getElementById("npdrop").style.display = "flex"}}>
                            <p className="project-title-text">Non-Profit</p>
                                &nbsp;&nbsp;
                                <a role="button" className="drop-menu fa fa-2x fa-caret-down drop-button" aria-label="menu" aria-expanded="false"
                                   aria-haspopup="true">
                                    <span aria-hidden="true"></span>
                                    <span aria-hidden="true"></span>
                                    <span aria-hidden="true"></span>
                                </a>
                        </div>
                        <div id="npdrop" className="item-desc">
                            <div className="level center-column">
                                <p className="para-text">
                                    Throughout my adult life I've devoted a significant amount of
                                    my time and effort to non-profit causes. I have a passion for the environment and I've
                                    worked with organizations like <a className="link-text" href="https://www.mevo.org" rel="noopener noreferrer" target="_blank">MEVO</a> to
                                    bring sustainability and equity to our collective habitat. I've also volunteered hundreds of hours with other organizations, both local and global, like
                                    <a className="link-text" href="https://www.sierraclub.org/" rel="noopener noreferrer" target="_blank"> Sierra Club </a>
                                    and <a className="link-text" href="http://mcifp.org/" rel="noopener noreferrer" target="_blank"> Interfaith Food Pantry</a>.
                                    <br/><br/>
                                    I always enjoy putting my skills to work and I also believe in "being the change".
                                    I'm drawn to grassroots organizations that do that everyday. I volunteer my time but
                                    I also volunteer some creations. I try to make things that have purpose and value for
                                    these organizations. At MEVO I helped to create their first real educational farm.
                                    At Interfaith Food Pantry I built a rainwater harvesting system for their educational garden.
                                    In the process I've met tons of great people and learned lots of new skills.
                                    <br/><br/>
                                    <p className="para-text has-text-centered">
                                        Still curious? <Link to="/Contact"><a className="is-link link-text"> Contact me</a></Link>
                                        , I'd love to tell you more about it.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="project-tile center-column animated" id="project6">
                        <div className="pdrop-control center" onClick={()=>{
                            document.getElementById("mildrop").style.display === "flex" ?
                                document.getElementById("mildrop").style.display = "none" :
                                document.getElementById("mildrop").style.display = "flex"}}>
                            <p className="project-title-text">Military Service</p>
                            &nbsp;&nbsp;
                            <a role="button" className="drop-menu fa fa-2x fa-caret-down drop-button" aria-label="menu" aria-expanded="false"
                               aria-haspopup="true">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>
                        <div id="mildrop" className="item-desc">
                            <div className="level center-column">
                                <p className="para-text">
                                    During college I volunteered for three years of service as a member of the
                                    <a className="link-text" href="https://www.nj.gov/military/guard/army/" rel="noopener noreferrer" target="_blank"> NJ Army National Guard</a>
                                    . I did almost a year of training in Oklahoma and Virginia after
                                    which I returned to NJ to become a member of F Company, 250th BSB, 50th IBCT.
                                    My company was attached to the 2/113th Infantry Battalion as a FSC. My job
                                    <a className="link-text" href="https://www.goarmy.com/careers-and-jobs/browse-career-and-job-categories/mechanics/small-arms-artillery-repairer.html" rel="noopener noreferrer" target="_blank"> (91F) </a>
                                    was to maintain weapons and equipment at both the company and battalion level.
                                    <br/><br/>
                                    I graduated the US Army Ordnance school at the top of my class and I maintained
                                    leadership positions throughout my military career. In the Army I learned how to be
                                    a better leader and how to work with a variety of people. I gained values and life
                                    experiences that define some of my best qualities.
                                    <br/><br/>
                                </p>
                                <p className="para-text has-text-centered">
                                    Still curious? <Link to="/Contact"><a className="is-link link-text"> Contact me</a></Link>
                                    , I'd love to tell you more about it.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Projects;