import React from 'react';
import NavBar from "./NavBar";

function About() {
    return (
        <div className="Resume hero is-fullh has-background-black-ter">
            <NavBar/>
                <div className="container center-column has-text-left">
                    <h1 className="welcome-text">About me</h1>
                    <p className="personal-text padding-1">
                        I grew up on the North East coast of the United States.
                        As a kid I was pretty rambunctious and I've always enjoyed high energy activities and being outside.
                        I played soccer, then got into skateboarding, snowboarding, and later rock climbing.
                        I've always been into puzzles, design, and creation. These things went hand in hand with my outdoor activities.
                        I would design and build ramps and rails for skateboarding and snowboarding.
                        I also liked video games as a kid,
                        my first console was an N64, and this translated to liking computers when games like
                        Starcraft started emerging in my teen years.
                        <br/><br/>
                        As a young adult I became more involved with the environment and I developed an interest in
                        sustainability. I spent a ton of time outdoors, became an experienced climber and started a couple of agricultural projects.
                        I served 3 years in the Army National Guard and earned 2 degrees in Geography and Computer Science.
                        <br/><br/>
                        I've continued a lot of my early passions into my adult life
                        but I've added a few more hobbies and a lot more skills. Like welding, brewing, and of course,
                        writing software. I love taking a DIY approach to complex projects, finding out how things work, then
                        designing and building my own solution.
                        <br/><br/>
                        Lastly, you might be wondering why software and farming. Software spans almost all technology.
                        It's an unlimited creation space with an endless frontier. Farming on the other hand, deals in finite and physical creation. 
                        It provides a fundamental connection between our lives and our environment. Together they provide a balance of purpose and inspiration.
                    </p>
                </div>
            </div>
    );
}

export default About;